import styled from 'styled-components';
import tw from 'tailwind.macro';
import { math } from 'polished';

export const XlParagraph = styled.p`
  ${tw`font-serif mb-32 text-2xl text-purple-300`};
  ${props =>
    (props.marginBottom === '0' && tw`mb-0`) ||
    (props.marginBottom === '6' && tw`mb-6`) ||
    (props.marginBottom === '8' && tw`mb-8`) ||
    (props.marginBottom === '12' && tw`mb-12`) ||
    (props.marginBottom === '14' && tw`mb-14`) ||
    (props.marginBottom === '16' && tw`mb-16`) ||
    (props.marginBottom === '24' && tw`mb-24`) ||
    (props.marginBottom === '32' && tw`mb-32`) ||
    (props.marginBottom === '48' && tw`mb-48`) ||
    (props.marginBottom === '64' && tw`mb-64`) ||
    (props.marginBottom === '144' && tw`mb-144`)}
  line-height: ${math('36/32')};

  &:last-child {
    ${tw`m-0`};
  }
`;

export const LgParagraph = styled.p`
  ${tw`font-serif mb-16 text-xl text-purple-300`};
  ${props =>
    (props.marginBottom === '0' && tw`mb-0`) ||
    (props.marginBottom === '6' && tw`mb-6`) ||
    (props.marginBottom === '8' && tw`mb-8`) ||
    (props.marginBottom === '12' && tw`mb-12`) ||
    (props.marginBottom === '14' && tw`mb-14`) ||
    (props.marginBottom === '16' && tw`mb-16`) ||
    (props.marginBottom === '24' && tw`mb-24`) ||
    (props.marginBottom === '32' && tw`mb-32`) ||
    (props.marginBottom === '48' && tw`mb-48`) ||
    (props.marginBottom === '64' && tw`mb-64`) ||
    (props.marginBottom === '144' && tw`mb-144`)}
  line-height: ${math('28/24')};

  &:last-child {
    ${tw`m-0`};
  }
`;

export const BaseParagraph = styled.p`
  ${tw`font-serif mb-32 text-lg text-purple-300`};
  ${props => props.marginBottom === '0' && tw`mb-0`};
  ${props =>
    (props.marginBottom === '0' && tw`mb-0`) ||
    (props.marginBottom === '6' && tw`mb-6`) ||
    (props.marginBottom === '8' && tw`mb-8`) ||
    (props.marginBottom === '12' && tw`mb-12`) ||
    (props.marginBottom === '14' && tw`mb-14`) ||
    (props.marginBottom === '16' && tw`mb-16`) ||
    (props.marginBottom === '24' && tw`mb-24`) ||
    (props.marginBottom === '32' && tw`mb-32`) ||
    (props.marginBottom === '48' && tw`mb-48`) ||
    (props.marginBottom === '64' && tw`mb-64`) ||
    (props.marginBottom === '144' && tw`mb-144`)}
  line-height: ${math('24/19')};

  a {
    ${tw`font-serif italic underline`};
  }
`;

export const SmParagraph = styled.p`
  ${tw`font-serif mb-48 text-base text-purple-300`};
  ${props =>
    (props.marginBottom === '0' && tw`mb-0`) ||
    (props.marginBottom === '6' && tw`mb-6`) ||
    (props.marginBottom === '8' && tw`mb-8`) ||
    (props.marginBottom === '12' && tw`mb-12`) ||
    (props.marginBottom === '14' && tw`mb-14`) ||
    (props.marginBottom === '16' && tw`mb-16`) ||
    (props.marginBottom === '24' && tw`mb-24`) ||
    (props.marginBottom === '32' && tw`mb-32`) ||
    (props.marginBottom === '48' && tw`mb-48`) ||
    (props.marginBottom === '64' && tw`mb-64`) ||
    (props.marginBottom === '144' && tw`mb-144`)}
  line-height: ${math('22/17')};
`;

export const XsParagraph = styled.p`
  ${tw`font-sans mb-32 relative text-base text-purple-300`};
  ${props =>
    (props.marginBottom === '0' && tw`mb-0`) ||
    (props.marginBottom === '6' && tw`mb-6`) ||
    (props.marginBottom === '8' && tw`mb-8`) ||
    (props.marginBottom === '12' && tw`mb-12`) ||
    (props.marginBottom === '14' && tw`mb-14`) ||
    (props.marginBottom === '16' && tw`mb-16`) ||
    (props.marginBottom === '24' && tw`mb-24`) ||
    (props.marginBottom === '32' && tw`mb-32`) ||
    (props.marginBottom === '48' && tw`mb-48`) ||
    (props.marginBottom === '64' && tw`mb-64`) ||
    (props.marginBottom === '144' && tw`mb-144`)}
  line-height: ${math('22/17')};

  &:last-child {
    ${tw`mb-0`};
  }

  a {
    ${tw`font-serif italic underline`};
  }
`;
